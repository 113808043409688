.todayTao{
    display: flex;
    flex-direction: column;
    margin-top: 1vw;
    .head{
        display: flex;
        flex-direction: row;
        font-size: 24px;
        align-items: center;
        >button{
            margin-left: 1vw;
        }
    }
}
.headNmae{
    color: green;
    font-size: 20px;
    margin-left: 1vw;
}