.articleManege{
    padding: 1rem;
    background: #FFFFFF;
    border-radius: .3rem;
    height: 100vh;
    overflow-y: auto;
}
.articleManageHeaderBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.articleManageHeader{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    font-size: 16px;
    padding-top: 1rem;
    >div{
        display: flex;
        justify-content: start;
        align-items: center;
        margin-right: .5rem;
        margin-bottom: 1rem;
    }
    .title{
        white-space: nowrap;
    }
}
.articleManageNav{
    display: flex;
    justify-content: start;
    align-items: center;
}
.articleHandleHeader{
    padding: .5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}
.fromItem{
    display: flex;
    justify-content: start;
    align-items: center;
}
.caozuoBtn{
    a{
        margin-right: .5rem;
    }
}
.topStyle{
    width: 0;
    height: 0;
    display: inline-block;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid red;
}
.articleNitem{
    display: flex;
    flex-direction: row;
}
.articleNinfo{
    margin-left: .3vw;
    display: flex;
    flex-direction: column;
    >span:nth-child(1){
        color: #222;
    }
    >span:nth-child(2){
        color: #999;
    }
    >span:nth-child(3){
        display: flex;
        margin-top: .3vw;
        align-items: center;
        >span{
            color: #FF7F50;
            margin-left: .3vw;
        }
    }
}
.articleNpush{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.articleRest {
    display: flex;
    flex-direction: row;
}
.oneLevelNames{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.statusExplain{
    text-align: left;
    color: #999;
}
.fromItemRow{
    display: flex;
    flex-direction: row;
}