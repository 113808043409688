.logo {
  height: 60px;
  padding: 15px;
  div{
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #FFFFFF;
    font-size: 18px;
    text-indent: 5px;
    img {
      width: 30px;
      height: 30px;
    }
  }
}
.headerText{
  color: #FFFFFF;
}