.articleManege{
    padding: 1rem;
    background: #FFFFFF;
    border-radius: .3rem;
}
.articleManageHeaderBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.articleManageHeader{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    font-size: 16px;
    padding-top: 1rem;
    >div{
        display: flex;
        justify-content: start;
        align-items: center;
        margin-right: .5rem;
        margin-bottom: 1rem;
    }
    .title{
        white-space: nowrap;
    }
}
.articleManageNav{

}
.articleHandleHeader{
    padding: .5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.fromItem{
    display: flex;
    justify-content: start;
    align-items: center;
}
.caozuoBtn{
    a{
        margin-right: .5rem;
    }
}
.spanTwoRow{
    .titleName{
        text-overflow: ellipsis;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: left;
        font-size: 11px;
        margin: .5vw 0;
        color:#666
    }
    .nickNma{
        text-overflow: ellipsis;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 11px;
        margin: .5vw 0;
        color:#096dd9;
        text-align: left;
    }
    button{
        border: 0;
        width:100%;
        margin: 0 auto;
        background: #000;
        font-size: 13px;
        color: #fff;
        border-radius: 10px;
        cursor:pointer;
        margin-top: .3vw;
    }
    img{
        cursor: pointer;
    }
}

.titname{
    color: #222;
    font-size: 13px;
}
.itemPrice{
    >span:nth-child(1){
        font-size: 15px;
    }
    >span:nth-child(2){
        font-size: 11px;
    }
    display: flex;
    align-items: self-end;
    flex-direction: row;
    color: red;
    text-align: left;
}
.hiddenVal{
    opacity: 0;

}
.buttonCopy{
    border: 0;
    width:100%;
    margin: 0 auto;
    background: #000;
    font-size: 13px;
    color: #fff;
    border-radius: 10px;
    cursor:pointer;
    margin-top: .3vw;
}
.superTaoForm{}
.superTaoForm{
    .left{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    display: flex;
    flex-direction: row;
}