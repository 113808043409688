.articleManege{
    padding: 1rem;
    background: #FFFFFF;
    border-radius: .3rem;
}
.articleManageHeaderBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.articleManageHeader{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    font-size: 16px;
    padding-top: 1rem;
    >div{
        display: flex;
        justify-content: start;
        align-items: center;
        margin-right: .5rem;
        margin-bottom: 1rem;
    }
    .title{
        white-space: nowrap;
    }
}
.articleManageNav{
    display: flex;
    justify-content: start;
    align-items: center;
}
.articleHandleHeader{
    padding: .5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}
.fromItem{
    display: flex;
    justify-content: start;
    align-items: center;
}
.caozuoBtn{
    a{
        margin-right: .5rem;
    }
}
.flexRow{
    display: flex;
    flex-direction: row;
    margin-bottom: 1vw;
}