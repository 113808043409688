.cheese_cdCardbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 3px 15px 0px rgba(153, 153, 153, 0.3);
    border-radius: 8px;
    padding: 16px 24px;

    .cheese_cdCardboxLeft {
        width: 49px;
        height: 49px;

        img {
            width: 49px;
            height: 49px;
        }
    }

    .cheese_cdCardboxRight {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 97px;
        height: 32px;
        background: #222222;
        border-radius: 16px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    }

    .cheese_cdCardboxMiddle {
        font-size: 18px;
        word-break: break-all;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        text-align: left;
    }
}


.cheese_tbCardbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 3px 15px 0px rgba(153, 153, 153, 0.3);
    border-radius: 8px;
    padding: 16px 24px;

    .cheese_tbCardboxLeft {
        width: 49px;
        height: 49px;

        img {
            width: 49px;
            height: 49px;
        }
    }

    .cheese_tbCardboxRight {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 97px;
        height: 32px;
        background: #222222;
        border-radius: 16px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    }

    .cheese_tbCardboxMiddle {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        text-align: left;
    }
}

.ck-editor__main .ck-content{
    height: 100vh;
}